import React from "react";

import {
  NotificationContainer,
  NotificationManager
} from "react-notifications";
import Card from "components/Card/Card.jsx";
import links from "../../views/Server.json";
import Button from "components/CustomButton/CustomButton.jsx";
import ReactLoading from "react-loading";
//import queryString from 'query-string';
//import {
//  NotificationManager
//} from "react-notifications";
//import Cookies from "js-cookie";

import { Row, Col } from "react-bootstrap";
//import links from "../../views/Server.json";

class Forgotpassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      resetPass: null,
      error: null,
      errors: {},
      isLoading: false,
    };
  }

  handlevalidation() {
    let errors = {};
    let formIsValid = true;
    // eslint-disable-next-line 
   // var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    if (!this.state.resetPass) {
      formIsValid = false;
      errors["confirmpass"] = "*Please enter Username or Email ID";
    }
    /*if((!reg.test(this.state.resetPass))){
      formIsValid = false;
      errors["confirmpass"] = "*Enter valid email Id";
    }*/
    this.setState({ errors: errors });

    return formIsValid;
  }

  resetPassword = e => {

    if (this.handlevalidation()) {
      this.setState({ isLoading: true });
    fetch(links.serverAddress + links.resetPassword, {
      method: "POST",

      body: JSON.stringify({
        username: this.state.resetPass,
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
    
      }
    })
      .then(response => {
        const statusCode = response.status;
        const data = response.json();

        return Promise.all([statusCode, data]);
      })
      .then(json => {
         if (json[0] === 400) {
          this.setState({ isLoading: false });
          NotificationManager.error("Invalid username or email id");
        } else if (json[0] === 401) {
          this.setState({ isLoading: false });
            NotificationManager.error("Invalid username or email id");
         
        } 
       else if (json[0] === 200) {
          
          this.setState({ isLoading: false });
          window.location.href = "/#/auth/login-page";
         /* setTimeout(() => {
                 window.location.href = "/#/auth/login-page";
             }, 300)*/
          //this.setState({resetPass: json[1]})
        }
      })
      .catch(error =>
        this.setState(
          {
            error,
            isLoading: false
          },
          NotificationManager.error("Server down, Please refresh the page after a minute")
        )
      );
        }
  }
  
  render() {
    const { isLoading } = this.state;

    if (isLoading) {
      return (
        <div className="main-content">
          {/* <Grid fluid> */}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <ReactLoading
                type="spin"
                color="#555555"
                height={"8%"}
                width={"8%"}
              />               
            </div>
            <br/>
            <span style={{fontSize:"150%",
             display: "flex",
             justifyContent: "center", 
             alignItems: "center", 
             color:"#777"}}>
             Reset password link has been sended, Please check your Email
             </span>
          {/* </Grid> */}
        </div>
      );
    }

    return (
    // <Grid>
        <Row>
          <Col md={6} sm={8} mdOffset={3} smOffset={3}>
            <form>
              <Card
                hidden={this.state.cardHidden}
                textCenter
                title="Forgot Password"
                content={
                  <div>
                    <Row>
                      <Col md={1}></Col>
                      <Col md={10}>
                        <label>Username</label>
                        <input
                          className="form-control"
                          name="user"
                          onKeyPress={e => {
                            if (e.key === "Enter") {
                              this.resetPassword();
                            }
                          }}
                          onChange={e => {
                            this.setState({ resetPass: e.target.value });
                          }} 
                          placeholder="Must have atleast 8 characters and alpha-numeric"
                          type="text"
                          required
                        />
                        <div className="error">
                           {this.state.errors["confirmpass"]}
                        </div>
                        <br></br>
                        <Button
                          bsStyle="info"
                          pullRight
                          fill
                          type="button"
                           onClick={() => {
                            setTimeout(() => {
              window.location.href = "/#/auth/login-page";
            }, 1000);
                          }} 
                        >
                          Cancel
                        </Button>
                        <Button
                          bsStyle="info"
                          pullRight
                          fill
                          type="button"
                          onClick={event => {
                            this.resetPassword();
                          }} 
                        >
                          Submit
                        </Button>
                      </Col>
                    </Row>
                  </div>
                }
                ftTextCenter
              />
            </form>
            <NotificationContainer/>
          </Col>
        </Row>
      // </Grid>
    );
  }
}
export default Forgotpassword;
