// Import
import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import Card from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import ReactTable from "react-table";
import Modal from 'react-responsive-modal';
import DatePicker from "react-datepicker";
import * as moment from "moment";
import Select from "react-select";
import {
  NotificationContainer,
  NotificationManager
} from "react-notifications";
import Cookies from "js-cookie";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-table/react-table.css";
import "react-notifications/lib/notifications.css";
import "react-tabs/style/react-tabs.css";
import "react-datepicker/dist/react-datepicker.css";
import links from "../views/Server.json";

const options = [
  { value: 1, label: "1 month" },
  { value: 3, label: "3 months" },
  { value: 12, label: "12 months" }
];
const price = [
  { value: 1, label: "15,000" },
  { value: 3, label: "43,000" },
  { value: 12, label: "1,72,000" }
];
class Subscription extends Component {
  constructor(props) {
    super(props);

    //State initialisation
    this.state = {
     renewalopen: false,
     macId:"",
     appId:"",
     status:"",
     expiryDate : new Date(),
     selectedOption: [],
     endDate: new Date(),
     price:"",
     licenceDetails: [],
     errorStatusCode: false,
     statusCode500: false,
     textErrorCode: ""
    };
  }
  handledropdownChange = selectedOption => {
    this.setState({ selectedOption });

    if (this.state.renewalopen === true) {
      //renew active state where expiry date will be modified
      if (this.state.status === "Active" || this.state.status === "Trial") {

        var d = this.state.expiryDate.getDate();
        var m = this.state.expiryDate.getMonth() + selectedOption.value;
        var y = this.state.expiryDate.getFullYear();
        if (m >= 12) {
          m = m - 12;
          y = y + 1;
        }
        this.state.endDate.setDate(d);
        this.state.endDate.setMonth(m);
        this.state.endDate.setFullYear(y);
      }
      for(let i=0; i<price.length; i++){
        if(selectedOption.value === price[i].value){
          this.setState({ price : price[i].label});
        }
      }
      
    
    }
  };

  subscriber = e => {
    let Token = "Token " + Cookies.get("access-token");
      fetch(links.serverAddress + links.userlicence, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: Token
        },
      })
        .then(response => {
          const statusCode = response.status;
          const data = response.json();
          if(statusCode === 500){
            this.setState({errorStatusCode: true, statusCode500: true, textErrorCode: "Oops! Internal Server Error..."})
          }
          else if(statusCode === 502 || statusCode === 504 ){
            this.setState({errorStatusCode: true, statusCode500: true, textErrorCode: "Oops! Bad Gateway Or Service Unavailable Error..."})
          }
          return Promise.all([statusCode, data]);
        })
        .then(json => {
          this.setState({ data: json });
          if (json[0] === 200) {
            this.setState({licenceDetails: json[1].data})
            let array = this.state.licenceDetails.slice()
            for(let i=0; i<this.state.licenceDetails.length; i++){
              if (
                moment(this.state.licenceDetails[i].expiryDate, "DD-MM-YYYY").startOf(
                  "day"
                ) >= moment(new Date()).startOf("day")
              )
              {
                array[i].status = "Active"
              }
              else if (
                this.state.licenceDetails[i].trial === true &&
                moment(
                  this.state.licenceDetails[i].graceExpiryDate,
                  "DD-MM-YYYY"
                ).startOf("day") >= moment(new Date()).startOf("day")
              )
              {
                array[i].status = "Trial"
                array[i].orderStartDate = array[i].graceIssueDate
                array[i].expiryDate = array[i].graceExpiryDate
              }
              else if (
                moment(
                  this.state.licenceDetails[i].graceExpiryDate,
                  "DD-MM-YYYY"
                ).startOf("day") < moment(new Date()).startOf("day")
              )
              {
                array[i].status = "Expired"
                // array[i].expiryDate = "N/A"
              }
              else if (
                moment(
                  this.state.licenceDetails[i].graceExpiryDate,
                  "DD-MM-YYYY"
                ).startOf("day") >= moment(new Date()).startOf("day") &&
                moment(this.state.licenceDetails[i].expiryDate, "DD-MM-YYYY").startOf(
                  "day"
                ) < moment(new Date()).startOf("day")
              )
              {
                array[i].status = "In Grace"
              }
              else if (
                  this.state.licenceDetails[i].period === 77
              )
              {
                array[i].status = "Perpectual"
              }

              if( this.state.licenceDetails[i].expiryDate === null ){
                array[i].expiryDate = "N/A"
              }
            }
            this.setState({licenceDetails: array})
          } else if (json[0] === 400) {
            NotificationManager.error(
              "Unable to get records"
            );
          } else if (json[0] === 401) {
            window.location.href = "/#/auth/login-page";
            NotificationManager.error(
              "Unauthorised! Please Login Again"
            );
          }
        })
        .catch(error =>
          this.setState(
            {
              error,
              isLoading: false
            },
            NotificationManager.error("Server unexpectedly lost the connection. Please try after some time.")
          )
        );
  
  };


  componentWillMount(){
    this.subscriber()
  }

  RenewlicenceDetails(row){
    this.setState({
      renewalopen: true,
      macId : row.hciId,
      appId : row.applicationName,
      status : row.status,
    },()=>{
      if (this.state.status === "Active") {
        this.setState({
          expiryDate : moment(row.expiryDate, "DD/MM/YYYY").toDate(),
          endDate: moment(row.expiryDate, "DD/MM/YYYY").toDate(),
        });
      }
    });
    this.openlicence();
  }
  openlicence(){
    this.setState({
      renewalopen: true
    });
  }
  closelicence = () =>{
    this.setState({
      renewalopen: false,
      price: "",
      selectedOption:[],
    });
  }
  onCloseErrorModal= () => {
    this.setState({
      errorStatusCode: true,
      statusCode500: true
    })
  }
    ///cancel button closes

  render() {
    let addcustomer;
    let statusdiv;
    let errorcode;
    if(this.state.statusCode500 === true){
      errorcode = (
        <Modal open={this.state.errorStatusCode} onClose={this.onCloseErrorModal} center>
          <br></br>
          <br></br>
          <Card
            title={"Server Error"}
            content={
              <div>
              <Row style={{ color: "#716e6eb3"}}>          
                <Col md={12}>
                      <span  style={{fontSize: "59px"}}>{this.state.textErrorCode}</span>
                    </Col>
                    
                    </Row>
              </div>
            }
          />
     
          <NotificationContainer />
        </Modal>
      );
     }
        // -----renewal active status
        if (this.state.status === "Active"
        ||
        this.state.status === "Expired" ||
         this.state.status === "In Grace" ||
         this.state.status === "Trial")
        {
        statusdiv = (
          
          <div>
            <Row>
              <div className="col-md-3">
                <label>Expiry Date </label>
                <br></br>
                <DatePicker
                  className="form-control"
                  selected={this.state.expiryDate}
                  selectsEnd
                  minDate={this.state.expiryDate}
                  startDate={this.state.expiryDate}
                  endDate={this.state.endDate}
                onChange={this.handleChangeEnd}
                  readOnly={true}
                  dateFormat="dd/MM/yyyy"
                />
              </div>
              <div className="col-md-3">
                <label>Subscription Period </label>
                <Select
                  name="licencePeriod"
                  value={this.state.selectedOption}
                  onChange={this.handledropdownChange}
                  options={options}
                  theme={theme => ({
                    ...theme,
                    borderRadius: 4,
                    colors: {
                      ...theme.colors,
  
                      primary25: "#CCCCCC",
                      primary: "#555555",
                      primary50: "#CCCCCC"
                    }
                    
                  })}
                  
                />
                
                
              </div>
              <div className="col-md-3">
                <label>Renewal Date </label>
                <br></br>
                <DatePicker
                  className="form-control"
                  selected={this.state.endDate}
                  selectsEnd
                  minDate={this.state.expiryDate}
                  startDate={this.state.expiryDate}
                  endDate={this.state.endDate}
                  onChange={this.handleChangeEnd}
                  readOnly={true}
                  dateFormat="dd/MM/yyyy"
                />
              </div>
             
            </Row>
          </div>
        );
                }
    if(this.state.renewalopen === true){
      addcustomer = (
              <Modal open={this.state.renewalopen} onClose={this.closelicence} center>
          <br></br>
          <Card
            title={'Licence Renewal Details'}
            content={
            <form>
              <Row>
                <div className="col-md-3">
                  <label>Machine Id </label>
                  <input
                    type="text"
                    placeholder="d5:17:a9:42:bc:9f"
                    className="form-control"
                    value={this.state.macId}
                    disabled
                  ></input>
                </div>

                <div className="col-md-3">
                  <label>Application </label>
                  <input
                    type="text"
                    className="form-control"
                    value={this.state.appId}
                    disabled
                  ></input>
                </div>

                <div className="col-md-3">
                  <label>Status </label>
                  <input
                    type="text"
                    className="form-control"
                    value={this.state.status}
                    disabled
                  ></input>
                </div>
                <div className="col-md-3">
                  <label>Price </label>
                  <input
                    type="text"
                    className="form-control"
                    value={this.state.price}
                    disabled
                  ></input>
                </div>
                <br></br>
              </Row>
              {statusdiv}
              <br></br>
              <Button
                bsStyle="info"
                pullRight
                fill
                type="button"
                 onClick={() => {
                  this.closelicence();
                }}
              >
                Cancel
              </Button>
              <Button
                bsStyle="info"
                pullRight
                fill
                type="button"
               /*  onClick={ 
                  this.closelicence();
                } */
              >
                Pay
              </Button>
              <NotificationContainer />
              <br></br>
              <br></br>
            </form>
            }
          />

          <NotificationContainer />
        </Modal>
      )
    }
    return (
      <div className="main-content">
        {/* <Grid fluid> */}
          <Row>
            <Col md={12} sm={12}>
              <Tabs
              >
                <TabList>
                  <Tab
                  >
                    Subscriptions
                  </Tab>
                </TabList>
                <TabPanel>
                  <Card
                    content={
                      <div>
                        <ReactTable
                          data={this.state.licenceDetails}
                          
                          columns={[
                            {
                              Header: "ID",
                              id: "row",
                              Cell: row => {
                                return <div>{row.index + 1}</div>;
                              },
                              sortable: false,
                              filterable: false,
                              width: 80,
                              foldable: true
                            },
                            {
                              Header: "Machine ID",
                              accessor: "hciId",
                              width: 150,
                              foldable: true
                            },
                            {
                              Header: "Application Name",
                              accessor: "applicationName",
                              width: 150,
                              foldable: true
                            },                         
                            {
                              Header: "Licence Start Date",
                              accessor: "orderStartDate",
                              width: 150,
                              foldable: true
                            },
                            {
                              Header: "Expiry Date",
                              accessor: "expiryDate",
                              width: 150,
                              foldable: true,
                            },
                            {
                              Header: "Status",
                              accessor: "status",
                              style: { textAlign: "center", 
                              padding: '16px 0px 16px 0px'
                              },
                              foldable: true,

                              width: 150,

                              getProps: (state, rowInfo) => {
                                if (rowInfo === undefined) return "";
                                else if (rowInfo.row.status === "Active")
                                  return {
                                    style: { backgroundColor: "#4BB543" }
                                  };
                                else if (rowInfo.row.status === "Expired")
                                  return {
                                    style: { backgroundColor: "#CD5C5C" }
                                  };
                                else if (rowInfo.row.status === "In Grace")
                                  return {
                                    style: { backgroundColor: "#FFD800" }
                                  };
                                else if (rowInfo.row.status === "Trial")
                                  return {
                                    style: { backgroundColor: "#0d98ba" }
                                  };
                                  else if (rowInfo.row.status === "Perpectual")
                                  return {
                                    style: { backgroundColor: "rgb(178, 188, 216)" }
                                  };
                                else
                                  return { style: { backgroundColor: "000" } };
                              }
                            },
                          ]}
                          defaultPageSize={10}
                          //showPaginationTop

                          showPaginationBottom={true}
                          className="-striped -highlight customer"
                          defaultFilterMethod={(filter, row, column) => {
              const id = filter.pivotId || filter.id;
              return row[id] !== undefined
                ? String(row[id])
                    .toLowerCase()
                    .indexOf(filter.value.toLowerCase()) !== -1
                : true;
            }}
          
                        />
                        <br></br>
                        
                        {/* <NotificationContainer /> */}
                        <br></br>
                        <br></br>
                      </div>
                    }
                  />
                </TabPanel>





              </Tabs>
            </Col>
          </Row>
          {addcustomer}
          {errorcode}
        {/* </Grid> */}
      </div>
    );
  }
}

export default Subscription;
