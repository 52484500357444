import React from "react";

//import {
 // NotificationContainer,
 // NotificationManager
//} from "react-notifications";
//import Cookies from "js-cookie";
import Card from "components/Card/Card.jsx";
//import links from "../../views/Server.json";
import Button from "components/CustomButton/CustomButton.jsx";
// import queryString from 'query-string';

import { Row, Col } from "react-bootstrap";
//let Token = "Token " + Cookies.get('access-token');

class ChangePassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: null,
      oldpass: null,
      newpass: null,
      confirmpass: null,
      error: null,
      errors: {}
    };
  }

  //validations
 /* handlevalidation() {
    let errors = {};
    let formIsValid = true;

    if (!this.state.oldpass) {
      formIsValid = false;
      errors["oldpass"] = "*New Password field can't be empty";
    }

    if (!this.state.newpass) {
      formIsValid = false;
      errors["newpass"] = "*New Password field can't be empty";
    }
    if (!this.state.confirmpass) {
      formIsValid = false;
      errors["confirmpass"] = "*New Password field can't be empty";
    }

    this.setState({ errors: errors });

    return formIsValid;
  }
  //password change after login page with inputs old password, new password and confirm password
  passwordChange = e => {
    console.log(e);
    console.log(
      JSON.stringify({
        old_password: this.state.oldpass,
        new_password1: this.state.newpass,
        new_password2: this.state.confirmpass
      })
    );
    if (this.handlevalidation()) {
      let Token = "Token " + Cookies.get("access-token");
      fetch(links.serverAddress + links.changePassword, {
        method: "POST",
        body: JSON.stringify({
          old_password: this.state.oldpass,
          new_password1: this.state.newpass,
          new_password2: this.state.confirmpass
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: Token
        }
      })
        .then(response => {
          const statusCode = response.status;
          const data = response.json();
          return Promise.all([statusCode, data]);
        })
        .then(json => {
          this.setState({ data: json });
          if (json[0] === 200) {
            console.log(json);
            NotificationManager.success("Successfully saved");
            this.redirect();
          } else if (json[0] === 400) {
            if (json[1].new_password2)
              NotificationManager.error(json[1].new_password2);
            else if (json[1].old_password)
              NotificationManager.error(json[1].old_password);
            else NotificationManager.error("Please type another password");
          } else if (json[0] === 401) {
            NotificationManager.error("Please Login to Change the password");
            setTimeout(() => {
              window.location.href = "/pages/login";
            }, 2500);
          }
        })
        .catch(error =>
          this.setState(
            {
              error
            },
            console.log(this.state.error),
            NotificationManager.error("Failed")
          )
        );
    }
  };*/
  // redirecting after clicking on the save button to dashboard page after 2.5 secs
  redirect() {
    setTimeout(() => {
      window.location.href = "/#/auth/login-page";
    }, 2500);
  }
  cancel() {
    setTimeout(() => {
    window.location.href = "/#/auth/login-page";
}, 1500);
  }
  
  render() {
    // let url = this.props.location.search;
  // let params = queryString.parse(url);
    return (
      // <Grid>
        <Row>
          <Col md={6} sm={8} mdOffset={3} smOffset={3}>
            <form>
              <Card
                hidden={this.state.cardHidden}
                textCenter
                title="Set Password"
                content={
                  <div>
                    <Row>
                      <Col md={1}></Col>
                      <Col md={10}>
                        <label> New Password</label>
                        <input
                          className="form-control"
                          name="newpass"
                          /* onKeyPress={e => {
                            if (e.key === "Enter") {
                              this.passwordChange();
                            }
                          }}
                          onChange={e => {
                            this.setState({ newpass: e.target.value });
                          }} */
                          placeholder="Must have atleast 8 characters and alpha-numeric"
                          maxLength="16"
                          type="password"
                          required
                        />
                        <div className="error">
                          {/* {this.state.errors["newpass"]} */}
                        </div>
                        <br></br>
                        <label> Confirm Password</label>
                        <input
                          className="form-control"
                          name="confirmpass"
                          /* onKeyPress={e => {
                            if (e.key === "Enter") {
                              this.passwordChange();
                            }
                          }}
                          onChange={e => {
                            this.setState({ confirmpass: e.target.value });
                          }} */
                          placeholder="Must have atleast 8 characters and alpha-numeric"
                          maxLength="16"
                          type="password"
                          required
                        />
                        <div className="error">
                          {/* {this.state.errors["confirmpass"]} */}
                        </div>
                        <br></br>
                        <Button
                          bsStyle="info"
                          pullRight
                          fill
                          type="button"
                           onClick={event => {
                            this.cancel();
                          }}
                        >
                          cancel
                        </Button>
                        <Button
                          bsStyle="info"
                          pullRight
                          fill
                          type="button"
                          /* onClick={event => {
                            this.passwordChange();
                          }} */
                        >
                          Save
                        </Button>
                      </Col>
                    </Row>
                  </div>
                }
                ftTextCenter
              />
            </form>
            
          </Col>
        </Row>
      // </Grid>
    );
  }
}
export default ChangePassword;
