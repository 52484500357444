//import UserPage from "views/Pages/UserPage.jsx";
import ChangePasswordPage from "views/Pages/ChangePassword.jsx";
import LoginPage from "views/Pages/LoginPage.jsx";
import ForgotPasswordPage from "views/Pages/ForgotPassword.jsx";
import ActivatePage from "views/Pages/activate.jsx";
//import PasswordPage from "views/Pages/Password.jsx";
import ResetPassword from "views/Pages/ResetPassword.jsx"
//import RegisterPage from "views/Pages/RegisterPage.jsx";
//import LockScreenPage from "views/Pages/LockScreenPage.jsx";
var routes = [
  {
    collapse: true,
    path: "/pages",
    name: "Pages",
    state: "openPages",
    icon: "pe-7s-gift",

    views: [
      // {
      //   path: "/user-page",
      //   layout: "/admin",
      //   name: "User Page",
      //   mini: "UP",
      //   component: UserPage
      // },
      {
        path: "/activate",
        name: "Activation Page",
        short: "Acti",
        layout: "/auth",

        component: ActivatePage
      },
      {
        path: "/reset",
        name: "Activation Page",
        short: "Acti",
        layout: "/auth",

        component: ActivatePage
      },
      {
        path: "/reset-password",
        name: "Set Password",
        short: "Set",
        layout: "/auth",

        component: ResetPassword
      },
     /* {
        path: "/password-page",
        name: "password",
        short: "Password",
        layout: "/auth",
        component: PasswordPage
      },*/

      {
        path: "/change-password-page",
        layout: "/auth",
        name: "Change Password Page",
        mini: "CP",
        component: ChangePasswordPage
      },
      {
        path: "/login-page",
        layout: "/auth",
        name: "Login Page",
        mini: "LP",
        component: LoginPage
      },
      {
        path: "/forgot-password-page",
        layout: "/auth",
        name: "Forgot Password Page",
        mini: "fpage",
        component: ForgotPasswordPage
      },

      // {
      //   path: "/register-page",
      //   layout: "/auth",
      //   name: "Register",
      //   mini: "RP",
      //   component: RegisterPage
      // },
      // {
      //   path: "/lock-screen-page",
      //   layout: "/auth",
      //   name: "Lock Screen Page",
      //   mini: "LSP",
      //   component: LockScreenPage
      // }
    ]
  }
];
export default routes;
