import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import Grid from "@material-ui/core/Grid";
import ReactLoading from "react-loading";
// import ReactSpeedometer from "react-d3-speedometer"
// react component used to create charts
//import ChartistGraph from "react-chartist";
// react components used to create a SVG / Vector map
//import { VectorMap } from "react-jvectormap";
//import Button from "components/CustomButton/CustomButton.jsx";
import Select from "react-select";
//import { MDBTable, MDBTableBody } from 'mdbreact';
// import { MDBCard, MDBCardBody, MDBCardHeader, MDBTable, MDBTableBody  } from 'mdbreact';

//import PieChart from 'react-minimal-pie-chart';

import Cards from "components/Card/Card.jsx";
import {
 NotificationContainer,
  NotificationManager
} from "react-notifications";
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
// import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
// import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Cookies from "js-cookie";
import Modal from "react-responsive-modal";
import {   Doughnut } from "react-chartjs-2";
import * as moment from "moment";
import StatsCard from "components/Card/StatsCard.jsx";
//import Tasks from "components/Tasks/Tasks.jsx";
import links from "../views/Server.json"
import "mdbreact/dist/css/style.css";
import './dasboard.css'

import {
 // dataPie,
 // dataSales,
 // optionsSales,
//  responsiveSales,
 // dataBar,
 // optionsBar,
//  responsiveBar,
  table_data
} from "variables/Variables.jsx";
//import { classicNameResolver } from "typescript";

/*var mapData = {
  AU: 760, BR: 550, CA: 120, DE: 1300, FR: 540, GB: 690, GE: 200, IN: 200, RO: 600, RU: 300, US: 2920};*/

const bardata = {
  data: canvas => {
    return {
      type: "bar",
      labels: [],
      datasets: [],
    };
  },
  options: {
    layout: {},
    maintainAspectRatio: false,
    tooltips: {
      backgroundColor: "#fff",
      titleFontColor: "#333",
      bodyFontColor: "#666",
      bodySpacing: 4,
      xPadding: 12,
      mode: "nearest",
      intersect: 0,
      position: "nearest"
    },
    legend: {
      position: "bottom",
      fillStyle: "#FFF",
      display: true
    },
    scales: {
      yAxes: [
        {
          ticks: {
            fontColor: "grey",
            fontStyle: "bold",
            beginAtZero: true,
            maxTicksLimit: 5
          },
          gridLines: {
            drawTicks: true,
            drawBorder: true,
            display: true
            //color: "grey",
            //zeroLineColor: "grey"
          }
        }
      ],
      xAxes: [
        {
          gridLines: {
            display: true,
            color: "rgba(255,255,255,0.1)"
          },
          ticks: {
            fontColor: "grey",
            fontStyle: "bold"
          }
        }
      ]
    }
  }
};

bardata.datas = {
  labels: [
    "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec", "Jan", "Feb", "Mar"
  ],
  datasets: [
    {
      label: "Subscription",
      data: [
        [3],[],[1],[],[5],[2],[1],[6],[3],[2],[],[5]         ],

      backgroundColor: [
        "#0d98ba", "#0d98ba", "#0d98ba", "#0d98ba", "#0d98ba", "#0d98ba", "#0d98ba", "#0d98ba", "#0d98ba", "#0d98ba", "#0d98ba", "#0d98ba"
      ]
    }
  ]
};
const Categories = {
  data: canvas => {
    return {
      responsive: true,
      type: "doughnut",
      labels: [],
      datasets: []
    };
  },
  options: {
    layout: {},

    maintainAspectRatio: false,
    tooltips: {
      backgroundColor: "#fff",
      titleFontColor: "#333",
      bodyFontColor: "#666",
      bodySpacing: 4,
      xPadding: 12,
      mode: "nearest",
      intersect: 0,
      position: "nearest"
    },
    legend: {
      position: "bottom",
      fillStyle: "#FFF",
      display: true
    },
    scales: {
      yAxes: [
        {
          ticks: {
            fontColor: "transparent",
            fontStyle: "bold",
            beginAtZero: true,
            maxTicksLimit: 5
          },
          gridLines: {
            drawTicks: true,
            drawBorder: true,
            display: true,
            color: "rgba(255,255,255,0.1)",
            zeroLineColor: "transparent"
          }
        }
      ],
      xAxes: [
        {
          gridLines: {
            display: true,
            color: "rgba(255,255,255,0.1)"
          },
          ticks: {
            fontColor: "rgba(255,255,255,0.4)",
            fontStyle: "bold"
          }
        }
      ]
    }
  }
};
var DateDiff = require("date-diff");

class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
     licenceDetails: [],
     macAppDetails: [],
     selectedCust: [],
     selectedApp: [],
     appDetails:[],
     macs:[],
     userData:null,
     mac: "",
     macId: "",
     app: "",
     appId: "",
     isTrial: false,
     isGrace: false,
     isToBe: false,
     isAlive: false,
     isDead: false,
     activeDaysCount: 0,
     totalActiveDaysCount: 0,
     garceDaysCount: 0,
     totalGarceDaysCount: 0,
     ExpireDaysCount: 0,
     errorStatusCode: false,
     statusCode500: false,
     isSpinner: true,
     textErrorCode: "",
     Category: "Not Defined"
    };
    this.machAppDeta();
  }

  createTableData() {
    var tableRows = [];
    for (var i = 0; i < table_data.length; i++) {
      tableRows.push(
        <tr key={i}>
          <td>
            <div className="flag">
              <img src={table_data[i].flag} alt="us_flag" />
            </div>
          </td>
          <td>{table_data[i].country}</td>
          <td className="text-right">{table_data[i].count}</td>
          <td className="text-right">{table_data[i].percentage}</td>
        </tr>
      );
    }
    return tableRows;
  }
  //----------Error Code Popup ------------When error on 500, 502, 504 status code 


 
  GetUser = e => {
    let Token = "Token " + Cookies.get("access-token");
    this.setState({isSpinner: true})
      fetch(links.serverAddress + links.userlicence + "?mid=" + this.state.macId + "&appid=" + this.state.appId,  {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: Token
        },
      })
        .then(response => {
          const statusCode = response.status;
          const data = response.json();
          return Promise.all([statusCode, data]);
        })
        .then(json => {
          this.setState({ data: json });
          if (json[0] === 200) {
            this.setState({isAlive: false, isToBe: false, isDead: false, isGrace: false, isTrial: false, isSpinner: false});
            this.setState({userData: json[1].data})
            console.log('userData', this.state.userData)
            if(this.state.userData.Category === '0'){
              this.setState({Category: 'Demo'})
            }else if(this.state.userData.Category === '1'){
              this.setState({Category: 'Standard'})
            }else if(this.state.userData.Category === '2'){
              this.setState({Category: 'Pro'})
            }else{
              this.setState({Category: 'Not Defined'})
            }
            // Check if licence is trial or not
            if (this.state.userData.trial === true){
              this.setState({isTrial: true});
              if(
                moment(this.state.userData.graceExpiryDate, "DD-MM-YYYY").toDate() >= moment(new Date()).toDate()
              ){
                //Check if Trial has started or not
                if( moment(this.state.userData.graceIssueDate, "DD-MM-YYYY").toDate() <= moment(new Date()).toDate() )
                {
                  this.setState({isAlive: true});
                  var duration = new DateDiff( moment(this.state.userData.graceExpiryDate, "DD-MM-YYYY").toDate(), moment(new Date()).toDate() );
                  this.setState({ activeDaysCount: Math.floor(duration.days() + 1) },()=>{
                  });
                } else {
                  this.setState({isToBe: true});
                }
                var totalDuration = new DateDiff(moment(this.state.userData.graceExpiryDate, "DD-MM-YYYY").toDate(),
                                                 moment(this.state.userData.graceIssueDate, "DD-MM-YYYY").toDate());
                this.setState({ totalActiveDaysCount: Math.floor(totalDuration.days() + 1) },()=>{
                });
              } else {
                this.setState({isDead: true});
              }
            }
            else if (
                      moment(this.state.userData.expiryDate, "DD-MM-YYYY").toDate() >= moment(new Date()).toDate()
                    ){
                        //Check if start is less than today's data to know if licence as started or not
                        if( moment(this.state.userData.orderStartDate, "DD-MM-YYYY").toDate() <= moment(new Date()).toDate() )
                        {
                          this.setState({isAlive: true});
                          duration = new DateDiff( moment(this.state.userData.expiryDate, "DD-MM-YYYY").toDate(), moment(new Date()).toDate() );
                          this.setState({ activeDaysCount: Math.floor(duration.days() + 1) });
                        } else{
                          this.setState({isToBe: true});
                        }
                        // this.state.userData.status = "Active";
                        totalDuration = new DateDiff(moment(this.state.userData.expiryDate, "DD-MM-YYYY").toDate(),
                                                        moment(this.state.userData.orderStartDate, "DD-MM-YYYY").toDate());
                        this.setState({ totalActiveDaysCount: Math.floor(totalDuration.days() + 1) });
                    }
            else if (
                      moment(this.state.userData.graceExpiryDate, "DD-MM-YYYY").toDate() /*startOf("day")*/ >= moment(new Date()).toDate()
                    ){
                      this.setState({isGrace: true});
                      if( moment(this.state.userData.graceIssueDate, "DD-MM-YYYY").toDate() <= moment(new Date()).toDate() )
                        {
                          this.setState({isAlive: true});
                          var graceDuration = new DateDiff(moment(this.state.userData.graceExpiryDate, "DD-MM-YYYY").toDate(), 
                                                          moment(new Date()).toDate());
                          this.setState({ activeDaysCount: Math.floor(graceDuration.days() + 1) });

                        } else{
                          this.setState({isToBe: true});
                        }
                        var TotalGraceDuration = new DateDiff(moment(this.state.userData.graceExpiryDate, "DD-MM-YYYY").toDate(),
                                                              moment(this.state.userData.graceIssueDate, "DD-MM-YYYY").toDate());

                        this.setState({ totalActiveDaysCount: Math.floor(TotalGraceDuration.days() + 1) })
                      }
            else if (
                      moment(this.state.userData.graceExpiryDate, "DD-MM-YYYY").toDate() < moment(new Date()).toDate()
                    ) {
                      this.setState({isGrace: true});
                      this.setState({isDead: true});
                      //  var expireDate =  moment(
                      //   this.state.userData.graceExpiryDate,
                      //   'DD-MM-YYYY'
                      // ).format()
                       TotalGraceDuration = new DateDiff(moment(this.state.userData.graceExpiryDate, "DD-MM-YYYY").toDate(),
                                                              moment(this.state.userData.graceIssueDate, "DD-MM-YYYY").toDate());

                      this.setState({ totalActiveDaysCount: Math.floor(TotalGraceDuration.days() + 1) })
                      var expireDate = new DateDiff(moment(new Date()).toDate(), moment(this.state.userData.graceExpiryDate, "DD-MM-YYYY").toDate());
                      this.setState({ ExpireDaysCount: Math.floor(expireDate.days()) });
                    }
          } else if (json[0] === 400) {
              NotificationManager.error(
                "Unable to get records"
              );
          } else if (json[0] === 401) {
              window.location.href = "/#/auth/login-page";
              NotificationManager.error(
                "Unauthorised! Please Login Again"
              );
          }
          else if(json[0] === 500){
            alert("enter 500")
          }
        })
        .catch(error =>
          {
            this.setState(
              {
                error,
                isLoading: false
              },NotificationManager.error("Server unexpectedly lost the connection. Please try after some time.")
            )
          }
        );
  
  };

  machAppDeta = e => {
    let Token = "Token " + Cookies.get("access-token");
      fetch(links.serverAddress + links.machappdetails, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: Token
        },
      })
        .then(response => {
          const statusCode = response.status;
          const data = response.json();
          if(statusCode === 500){
            this.setState({errorStatusCode: true, statusCode500: true, textErrorCode: "Oops! Internal Server Error..."})
          }
          else if(statusCode === 502 || statusCode === 504 ){
            this.setState({errorStatusCode: true, statusCode500: true, textErrorCode: "Oops! Bad Gateway Or Service Unavailable Error..."})
          }
          return Promise.all([statusCode, data]);
          
        })
        .then(json => {
          this.setState({ data: json });
          if (json[0] === 200) {
            
            this.setState({macAppDetails: json[1].data, errorStatusCode: false, statusCode500: false})
            let array = this.state.macAppDetails.slice()
            for (let i = 0; i < this.state.macAppDetails.length; i++) {
              array[i].value = this.state.macAppDetails[i].id;
              array[i].label = this.state.macAppDetails[i].MachineID;
            }
            this.setState({ appDetails: array },()=>{
            });
            this.setState({selectedCust : [this.state.appDetails[0]]})
            this.setState(
              { macId: this.state.selectedCust[0].value, mac: this.state.selectedCust[0].label},
              () => {
               let array = this.state.appDetails.slice()
            for (let i = 0; i < this.state.appDetails.length; i++) {
              if(this.state.macId === this.state.appDetails[i].value)
              {
              for(let j=0; j < this.state.appDetails[i].Apps.length; j++){
                array[i].Apps[j].value = this.state.appDetails[i].Apps[j].id;
                array[i].Apps[j].label = this.state.appDetails[i].Apps[j].ApplicationName;
                this.setState({ appDetails: array },()=>{
                this.setState({macs: this.state.appDetails[i].Apps},()=>{
                  this.setState({selectedApp : [this.state.macs[0]]},()=>{
                    this.setState(
                      { app: this.state.selectedApp[0].label, appId: this.state.selectedApp[0].value },
                      () => {
                        this.GetUser()
                      }
                    );
                  })
                })
                });
              }
              }
        
            }
          }
        );
          } else if (json[0] === 400) {
            NotificationManager.error(
              "Unable to get records"
            );
          } else if (json[0] === 401) {
            window.location.href = "/#/auth/login-page";
            NotificationManager.error(
              "Unauthorised! Please Login Again"
            );
          }
          else if(json[0] === 500){
            // console.log("enter 500")
          }
        })
        .catch(error =>
          this.setState(
            {
              error,
              isLoading: false
            },
            NotificationManager.error("Server unexpectedly lost the connection. Please try after some time.")
          )
        );
  
  };

  handledropdownChangeMac = selectedCust => {
    this.setState({ selectedCust,
    userData: null,
    selectedApp: [],
    activeDaysCount: 0,
    garceDaysCount: 0,
    ExpireDaysCount: 0,
   });
  
  //Setting app automatically to avoid showing empty licence details
  let index = this.state.appDetails.findIndex(p => {
    return p.MachineID === selectedCust.MachineID;
  })
  let macObj = this.state.appDetails[index];
  let appObj = macObj.Apps;
  let index1 = this.state.appDetails[index].Apps.findIndex(p => {
  return p.id === appObj[0].id;
  })
  let appObjj = this.state.appDetails[index].Apps[index1];
  this.setState({appId:appObjj.id, app:appObjj.ApplicationName, selectedApp:appObjj},()=>{
    this.GetUser();
  });


  this.setState(
    { macId: selectedCust.id, mac: selectedCust.MachineID},
    () => {

      let array = this.state.macAppDetails.slice()
      for (let i = 0; i < this.state.macAppDetails.length; i++) {
        if(this.state.macId === this.state.macAppDetails[i].value)
        {
          for(let j=0; j < this.state.macAppDetails[i].Apps.length; j++){
            array[i].Apps[j].value = this.state.macAppDetails[i].Apps[j].id;
            array[i].Apps[j].label = this.state.macAppDetails[i].Apps[j].ApplicationName;
            this.setState({ macAppDetails: array },()=>{
            this.setState({macs: this.state.macAppDetails[i].Apps},()=>{
              })
            });
          
          }
        }
      }
    }
  );
  };

  handledropdownChangeApp = selectedApp => {
    this.setState({ selectedApp,
      activeDaysCount: 0,
      garceDaysCount: 0,
      ExpireDaysCount: 0, });
    this.setState(
      { app: selectedApp.label, appId: selectedApp.value },
      () => {
        this.GetUser()
      }
    );
  };
  onCloseErrorModal= () => {
    this.setState({
      errorStatusCode: true,
      statusCode500: true
    })
  }
  // componentWillMount(){

  //   setTimeout(
  //     function() {
  //       this.subscriber();
  //     }
  //     .bind(this),
  //     5000
  // );
  // this.GetUser();
  // }
  render() {
    let errorcode;
    if(this.state.statusCode500 === true){
      errorcode = (
        <Modal open={this.state.errorStatusCode} onClose={this.onCloseErrorModal} center>
          <br></br>
          <br></br>
          <Cards
            title={"Server Error"}
            content={
              <div>
              <Row style={{ color: "#716e6eb3"}}>          
                <Col md={12}>
                      <span  style={{fontSize: "59px"}}>{this.state.textErrorCode}</span>
                    </Col>
                    
                    </Row>
              </div>
            }
          />
     
          <NotificationContainer />
        </Modal>
      );
     }
    // const data_panel = {
    //   rows: [
    //     {
    //       'check': 'In order to streamline support requests and better serve you,we utilize a support ticket system.',
    //     },
    //     {
    //       'check': 'Valid Customer ID, Appliance Serial number and Support contract are required to submit a ticket.',
    //     },
    //     {
    //       'check': 'Standard support package 9 X 6 (0930 to 0630 Hours IST) excl. National holidays.',
    //     },
    //     {
    //       'check': 'Extended support Package 24 X 7.',
    //     },
    //     {
    //       'check': <div><span style={{"align":"center","marginLeft": "40px","fontSize": "14px"}}>Web </span> <a href="http://stackuptech.com/support/"  style={{"marginLeft": "50px","fontSize": "13px"}}  target="_blank" rel="noopener noreferrer">www.stackuptech.com/support</a></div> ,
    //     },
    //     {
    //       'check': <div><span style={{"align":"center","marginLeft": "40px","fontSize": "14px"}}>E-mail</span>  <a href="mailto:support\@stackuptech.com"  target="_top" style={{"marginLeft": "40px","fontSize": "13px"}}>support@stackuptech.com</a></div> ,
    //     },
    //     {
    //       'check': <div><span style={{"align":"center","marginLeft": "40px","fontSize": "14px"}}>Phone</span> <span style={{"marginLeft": "38px","fontSize": "13px"}}>+91 44 46200100</span></div> ,
    //     }
    //   ]
    // };
    // var obj = {};
  // console.log('this.state.userData.licence_attrs', this.state.userData !== null ? this.state.userData.licence_attrs : null, this.state.userData !== null ? this.state.userData.licence_attrs.length === 0 || Object.keys(obj).length === 0 :  null,
  // this.state.userData !== null ? Object.keys(this.state.userData.licence_attrs).length === 0 :  null)
    if(this.state.userData !== null){
      if(this.state.userData.period === 77){
        var hidecar = true
        var colChange = 12
      }
      else if(this.state.userData.period !== 77){
        hidecar = false
        colChange = 8
      }
    var machineName = this.state.userData.hciId
    var appName = this.state.userData.applicationName
    var Start_Date;
    var Expire_Date;
    var licType;
    var text;
    if(this.state.isTrial === false && this.state.isGrace === false)
    {
      Start_Date = this.state.userData.orderStartDate
      Expire_Date = this.state.userData.expiryDate
      licType = "Subscription Statistics For the Licence"
    } else {
      Start_Date = this.state.userData.graceIssueDate
      Expire_Date = this.state.userData.graceExpiryDate
      if(this.state.isTrial === true)
      {
        licType = "Subscription Statistics For the Trial";
        text = "Trail Expired, Days elapsed : "
      } else {
        licType = "Subscription Statistics For the Grace Provided";
        text = "Grace Expired, Days elapsed : "
      }
    }
    var count;
    var graphtext;
    var color;
    var leftOut;
    let chartData = [];
    if(this.state.isToBe){
      text = "Will be Active in : "
      graphtext="Will be Active in : "
      color= "#4BB543"
      chartData = [ this.state.totalActiveDaysCount ]
      if (this.state.isTrial === false && this.state.isGrace === false){
        count = Math.abs(Math.floor(new DateDiff(moment(this.state.userData.orderStartDate, "DD-MM-YYYY").toDate(),
                                                  moment(new Date()).toDate()
                                                ).days() + 1));
      } else {
        count = Math.abs(Math.floor(new DateDiff(moment(this.state.userData.graceIssueDate, "DD-MM-YYYY").toDate(),
                                                  moment(new Date()).toDate()).days() + 1)
                                                );
      }
      Categories.data = {
        labels: [graphtext, "Days Used"],
        datasets: [
                    {
                      label: [
                        "one","two"
                      ],
                      data: [...chartData],
                      backgroundColor: [
                        color, "#CD5C5C"
                      ]
                    }
                  ]
      };
    } else if(this.state.isAlive){
      text = "Days remaining "
      graphtext="Days remaining "
      if(this.state.isTrial === true || this.state.isGrace === true) {
        color= "#FFFF00";
      } else {
        color=  "#4BB543";
      }
      color= "green";
      count = this.state.activeDaysCount;
      leftOut = this.state.totalActiveDaysCount - count;
      chartData = [ count, leftOut ];
      Categories.data = {
        labels: [graphtext, "Days Used"],
        datasets: [
                    {
                      label: [
                        "one","two"
                      ],
                      data: [...chartData],
                      backgroundColor: [
                        color, "#CD5C5C"
                      ]
                    }
                  ]
      };
    } else if(this.state.isDead){
      

      if (this.state.isTrial === false && this.state.isGrace === false){
        count = Math.floor(new DateDiff(moment(new Date()).toDate(),
                                        moment(this.state.userData.expireDate, "DD-MM-YYYY").toDate()
                                        ).days());  

      } else {        
       
        count = Math.floor(new DateDiff(moment(new Date()).toDate(),
                                        moment(this.state.userData.graceExpiryDate, "DD-MM-YYYY").toDate()
                                        ).days());
        leftOut = Math.floor(new DateDiff( moment(this.state.userData.graceExpiryDate, "DD-MM-YYYY").toDate(),
                                        moment(this.state.userData.graceIssueDate, "DD-MM-YYYY").toDate() 
                                        ).days() + 1);                                        
      }
      chartData = [ leftOut ]

      Categories.data = {
        labels: [ "Days Used"],
        datasets: [
                    {
                      label: [
                        "one","two"
                      ],
                      data: [...chartData],
                      backgroundColor: [
                       "#CD5C5C"
                      ]
                    }
                  ]
      };
    }
  }
    /*const dataMock = [
      { title: 'One', value: 10, color: '#E38627' },
      { title: 'Two', value: 15, color: '#C13C37' },
      { title: 'Three', value: 20, color: '#6A2135' },
    ];*/
// console.log('this.state.userData', this.state.userData !== null ? this.state.userData.licence_attrs : null)

    return (
      <div className="main-content">
        <Grid>
          <Row>
            <Col md={12}>
                      
              <div className="col-md-3">
                <label>Machine Id </label>
                <Select
                  value={this.state.selectedCust}
                  onChange={this.handledropdownChangeMac}
                  options={this.state.macAppDetails}
                  theme={theme => ({
                    ...theme,
                    borderRadius: 4,
                    colors: {
                      ...theme.colors,

                      primary25: "#CCCCCC",
                      primary: "#555555",
                      primary50: "#CCCCCC"
                    }
                  })}
                />
                </div>
                  <div className="col-md-3">
                    <label>Application </label>
                    <Select
                      value={this.state.selectedApp}
                      onChange={this.handledropdownChangeApp}
                      options={this.state.macs}
                      theme={theme => ({
                        ...theme,
                        borderRadius: 4,
                        colors: {
                          ...theme.colors,

                          primary25: "#CCCCCC",
                          primary: "#555555",
                          primary50: "#CCCCCC"
                        }
                      })}
                    />
                </div>
                <Col md={6}>
                <br/>
                <label style={{fontSize: '20px'}}>Category</label>
                    <span className="categoryTxt">{this.state.Category}</span>
                </Col>
            </Col>
          </Row>
    <br></br>
      <Row>
          <Col md={12}>
              <Cards
                title=  {<span style={{fontSize: '20px'}}>Subscription</span>}
                category="Subscription details"
                content={
                <div>
                  <div md={12}>
                    <Col lg={3} sm={6}>     
                      <StatsCard
                        bigIcon={<i style={{ fontSize: "45px", color : "lightblue" }} className="fa fa-industry"  />}
                        statsText={"Machine"}
                        statsValue={machineName ? machineName : 'N/A'}
                        statsIcon={<i className="fa fa-refresh" />}
                        statsIconText="Updated now"
                      />
                    </Col>
                    <Col lg={3} sm={6}>     
                      <StatsCard
                        bigIcon={<i style={{ fontSize: "45px", color : "lightblue" }} className="fa fa-paper-plane-o" />}
                        statsText={"Application"}
                        statsValue={appName ? appName : 'N/A'}
                        style={{fontSize:"50px"}}
                        statsIcon={<i className="fa fa-refresh" />}
                        statsIconText="Updated now"
                      />
                    </Col>
                    <Col lg={3} sm={6}>     
                      <StatsCard
                        bigIcon={<i style={{ fontSize: "45px", color : "lightblue" }} className="fa fa-hourglass-start" />}
                        statsText={"Start Date"}
                        statsValue={Start_Date ? Start_Date : 'N/A'}
                        statsIcon={<i className="fa fa-refresh" />}
                        statsIconText="Updated now"
                      />
                    </Col>
                    <Col lg={3} sm={6}>     
                      <StatsCard
                        bigIcon={<i style={{ fontSize: "45px", color : "lightblue" }} className="fa fa-hourglass-end" />}
                        statsText={"Expire Date"}
                        statsValue={Expire_Date ? Expire_Date : 'N/A' }
                        statsIcon={<i className="fa fa-refresh" />}
                        statsIconText="Updated now"
                      />
                    </Col>
                  </div>
                </div>
                }
              />
            </Col>
      </Row>
      <br></br>
      <br></br>
      {this.state.userData !== null ? this.state.userData.licence_attrs.length === 0 || Object.keys(this.state.userData.licence_attrs).length === 0 ? null : <Row>
      <Col md={12}>
      <Card variant="outlined">
      <CardHeader
        title={<span style={{fontSize: '19px', fontWeight: '300'}}>Device's</span>}
      />
      <CardContent>
      {this.state.isSpinner ? <Row><span className='spinnerStyl'><ReactLoading type="cylon" color="#000" /> Loading</span></Row> :
      <Row style={{padding:'0px 0px 0px 15px'}}>
      {this.state.userData !== null ? this.state.userData.licence_attrs.map((value, index) => (
                <Col md={4} key={index} className='deviceStyle'>
                  <span>{value.name} : {value.value !== null ? value.value : 0}</span>
                </Col>
                )) : null}
      </Row>}
</CardContent>    
</Card>
    </Col>
    </Row> : null}
      <br></br>
      <br></br>
      <Row className='cardSizing'>
        <Col md={4} hidden={hidecar}>
      <Card variant="outlined" style={{height : '100%'}}>
      <CardHeader
        title={<span style={{fontSize: '19px', fontWeight: '300'}}>{licType}</span>}
        subheader={<div className='cardSubheader'>
          <label className='cardSubheader'>{text}</label>{" "+count}
        </div>}/>
      <CardContent>
      <Row>
                    <Doughnut
                    data={Categories.data}
                    options={Categories.options}
                    onElementsClick={elems => {
                    }}
                    width={300}
                    height={300}
                  /> 
                  </Row>
      </CardContent>
      {/* <CardActions>
        <Button size="small">Learn More</Button>
      </CardActions> */}
    </Card>
    </Col>
    <Col md={colChange}>
      <Card variant="outlined" style={{height : '100%'}}>
      <CardHeader
        title={<span style={{fontSize: '19px', fontWeight: '300'}}>Stackup Tech Support</span>}
      />
      <CardContent>
        <Typography variant="h5" component="h2">
        In order to streamline support requests and better serve you,we utilize a support ticket system.
        </Typography>
        <br/>
        <Typography variant="h5" component="h2">
        Valid Customer ID, Appliance Serial number and Support contract are required to submit a ticket.
        </Typography>
        <br/>
        <Typography variant="h5" component="h2">
        Standard support package 9 X 6 (0930 to 0630 Hours IST) excl. National holidays.
        </Typography>
        <br/>
        <Typography variant="h5" component="h2">
        Extended support Package 24 X 7.
        </Typography>
        <br/><br/>
        <Typography color="textSecondary" style={{fontSize:'1.5rem'}}>
        Web
        <a href="http://stackuptech.com/support/"  style={{"marginLeft": "50px","fontSize": "1.5rem"}}  target="_blank" rel="noopener noreferrer">www.stackuptech.com/support</a>
        </Typography>
        <br/>
        <Typography color="textSecondary" style={{fontSize:'1.5rem'}}>
        E-mail
        <a href="mailto:support\@stackuptech.com"  target="_top" style={{"marginLeft": "40px","fontSize": "1.5rem"}}>support@stackuptech.com</a>
        </Typography>
        <br/>
        <Typography color="textSecondary" style={{fontSize:'1.5rem'}}>
        Phone
        <span style={{"marginLeft": "38px","fontSize": "1.5rem"}}>+91 44 46200100</span>
        </Typography>
      </CardContent>
      {/* <CardActions>
        <Button size="small">Learn More</Button>
      </CardActions> */}
    </Card>
    </Col>
    </Row>
      {/* <Row>
        <Col md={4}>
          <Cards
            title= {licType}
            content={
              <div>
                <div>
                  <label>{text}</label>{" "+count}
                </div>
                  <Row>
                    <Doughnut
                    data={Categories.data}
                    options={Categories.options}
                    onElementsClick={elems => {
                    }}
                    width={300}
                    height={300}
                  /> 
                  </Row>
                  <div style={{ height: 22 }}></div>
              </div>
            }
          />
        </Col>

      <Col md={8}>
      <Cards narrow
            content={
              <div>
              <MDBCard narrow>
      <MDBCardHeader className="view view-cascade gradient-card-header blue-gradient d-flex justify-content-between align-items-center py-2 mx-4 mb-3">
        <Row md={4}></Row>
        <Row md={4} style={{fontSize: "18px"}}>
        <span className="white-text mx-3">Stackup Tech Support</span>
        </Row>
        <Row md={4}></Row>
      </MDBCardHeader>
      <MDBCardBody cascade>
        <MDBTable borderless>
          <MDBTableBody rows={data_panel.rows} />
        </MDBTable>
      </MDBCardBody>
    </MDBCard>
    <br/>
              </div>
            }
          />
      </Col>
      </Row> */}
      <NotificationContainer/>
      {errorcode}
    </Grid>
  </div>
  );
}
}

export default Dashboard;
