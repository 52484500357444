import React from "react";

//import {
 // NotificationContainer,
 // NotificationManager
//} from "react-notifications";
import Card from "components/Card/Card.jsx";
//import links from "../../views/Server.json";
import Button from "components/CustomButton/CustomButton.jsx";
import queryString from 'query-string';
import {
  NotificationManager, NotificationContainer
} from "react-notifications";
//import Cookies from "js-cookie";

import { Row, Col } from "react-bootstrap";
import links from "../../views/Server.json";
//let Token = "Token " + Cookies.get('access-token');

class Activation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: null,
      password: null,
      confirmPassword: null,
      error: null,
      activationId:0,
      errors: {}
    };
  }

  handlevalidation() {
    let errors = {};
    let formIsValid = true;
    if (!this.state.password) {
      formIsValid = false;
      errors["newpass"] = "*Password field can't be empty";
    }
    if (!this.state.confirmPassword) {
      formIsValid = false;
      errors["confirmpass"] = "*Confrim Password field can't be empty";
    }

    this.setState({ errors: errors });

    return formIsValid;
  }

  checkActivation() {
   // let Token = "Token " + Cookies.get("access-token");
    let url = this.props.location.search;
    let params = queryString.parse(url);
    let parmToken = params.token
    let parmUid = params.uid
    fetch(links.serverAddress + links.activation, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
       // Authorization: Token
      },

      body: JSON.stringify({
        uid: parmUid,
        token: parmToken
      })
    })
      .then(response => {
        const statusCode = response.status;
        const data = response.json();

        return Promise.all([statusCode, data]);
      })
      .then(json => {
        if (json[0] === 400) {
          NotificationManager.error("User activation has been expired");
        } else if (json[0] === 401) {
          window.location.href = "/#/auth/login-page";
        } else if (json[0] === 200) {
          NotificationManager.success("Your account successfully activate ");
          this.setState({activationId: json[1]})
          this.setState({activationId : this.state.activationId.id})
        }
      })
      .catch(error =>
        this.setState(
          {
            error,
            isLoading: false
          },
          NotificationManager.error("Server down, Please refresh the page after a minute")
        )
      );
  }

  confirmLogin = e => {
    if (this.handlevalidation()) {
      fetch(links.serverAddress + links.activatePassword, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          id: this.state.activationId,
          password1: this.state.password,
          password2: this.state.confirmPassword
        })
      })
        .then(response => {
          const statusCode = response.status;
          const data = response.json();
          return Promise.all([statusCode, data]);
        })
        .then(json => {
          this.setState({ data: json });
          if (json[0] === 200) {
            NotificationManager.success("Password Successfully activated");
            setTimeout(() => {
                   window.location.href = "/#/auth/login-page";
               }, 2000)
          } else if (json[0] === 400) {
            NotificationManager.error(
              "Please type the correct username and password"
            );
          } else if (json[0] === 401) {
            NotificationManager.error(
              "Unauthorised! Please type the correct username and password"
            );
          }
        })
        .catch(error =>
          this.setState(
            {
              error,
              isLoading: false
            },
            NotificationManager.error("Server unexpectedly lost the connection. Please try after some time.")
          )
        );
    }
  };

  componentDidMount() {
    this.checkActivation();
   }
  
  render() {

    return (
    // <Grid>
        <Row>
          <Col md={6} sm={8} mdOffset={3} smOffset={3}>
            <form>
              <Card
                hidden={this.state.cardHidden}
                textCenter
                title="Set Password"
                content={
                  <div>
                    <Row>
                      <Col md={1}></Col>
                      <Col md={10}>
                        <label> Password</label>
                        <input
                          className="form-control"
                          name="password"
                           onKeyPress={e => {
                            if (e.key === "Enter") {
                              this.confirmLogin();
                            }
                          }}
                           onChange={e => {
                              this.setState({ password: e.target.value });
                            }} 
                          placeholder="Must have atleast 8 characters and alpha-numeric"
                          maxLength="16"
                          type="password"
                          required
                        />
                        <div className="error">
                         {this.state.errors["newpass"]} 
                        </div>
                        <br></br>
                        <label> Confirm Password</label>
                        <input
                          className="form-control"
                          name="confirmpassword"
                           onKeyPress={e => {
                            if (e.key === "Enter") {
                              this.confirmLogin();
                            }
                          }}
                          onChange={e => {
                            this.setState({ confirmPassword: e.target.value });
                          }} 
                          placeholder="Must have atleast 8 characters and alpha-numeric"
                          maxLength="16"
                          type="password"
                          required
                        />
                        <div className="error">
                           {this.state.errors["confirmpass"]} 
                        </div>
                        <br></br>
                        <Button
                          bsStyle="info"
                          pullRight
                          fill
                          type="button"
                           onClick={() => {
                            this.confirmLogin();
                          }} 
                        >
                          Set
                        </Button>
                      </Col>
                    </Row>
                  </div>
                }
                ftTextCenter
              />
            </form>
            <NotificationContainer/>
          </Col>
        </Row>
      // </Grid>
    );
  }
}
export default Activation;
