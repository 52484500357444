import React, { Component } from "react";
import {  Row, Col } from "react-bootstrap";
import links from "../../views/Server.json";
import {
  NotificationContainer,
  NotificationManager
} from "react-notifications";
import Cookies from "js-cookie";
import Card from "components/Card/Card.jsx";

import Button from "components/CustomButton/CustomButton.jsx";
//let Token = "Token " + Cookies.get('access-token');
const buttonStyle = {
  fontSize: "18px",
  border: "transparent",
  background: " rgb(4, 7, 7)",
  color: "white"
};
class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cardHidden: true,
      data: null,
      userName: null,
      password: null,
      confirmPassword: null,
      error: null,
      firstLogin: null,
      errors: {}
    };
  }
  componentDidMount() {
    setTimeout(
      function() {
        this.setState({ cardHidden: false });
      }.bind(this),
      700
    );
  }
  handleKeyPress = event => {
    if (event.key === "Enter") {
      this.login();
    }
  };

  //Validations
  handlevalidation() {
    let errors = {};
    let formIsValid = true;

    if (!this.state.userName) {
      formIsValid = false;
      errors["userName"] = "*Username can't be empty";
    }

    if (!this.state.password) {
      formIsValid = false;
      errors["password"] = "*Password can't be empty";
    }

    this.setState({ errors: errors });

    return formIsValid;
  }
  //Authorization
  login = e => {
    if (this.handlevalidation()) {
      fetch(links.serverAddress + links.login, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          username: this.state.userName,
          password: this.state.password
        })
      })
        .then(response => {
          const statusCode = response.status;
          const data = response.json();
          return Promise.all([statusCode, data]);
        })
        .then(json => {
          this.setState({ data: json });
          if (json[0] === 200) {
            Token = json[1].key;
            Cookies.set("access-token", Token);
            this.setState({ firstlogin: json[1].isFirstLogin });
            if (json[1].isFirstLogin === true) {
              window.location.href = "/#/auth/reset-password";
            } else {
              //if it is not first login redirect of dashboard page
              window.location.href = "/#/admin/licence";
            }
          } else if (json[0] === 400) {
            NotificationManager.error(
              "Please type the correct username and password"
            );
          } else if (json[0] === 401) {
            NotificationManager.error(
              "Unauthorised! Please type the correct username and password"
            );
          }
        })
        .catch(error =>
          this.setState(
            {
              error,
              isLoading: false
            },
            NotificationManager.error("Server unexpectedly lost the connection. Please try after some time.")
          )
        );
    }
  };
  // Forgot password - redirecting to forgot password
  forgotPassword = e => {
    if (this.state.firstlogin === "false") {
      window.location.href = "/#/auth/forgot-password-page";
    }
    //else
    // NotificationManager.error("Please Login with default username and password")
  };

  render() {
    return (
      // <Grid>
        <Row>
          <Col md={4} sm={6} mdOffset={4} smOffset={3}>
            <form>
              <Card
                hidden={this.state.cardHidden}
                textCenter
                title="Login"
                content={
                  <div>
                    <Row>
                      <Col md={12}>
                        <div>
                          <label>Username/Email ID</label>
                          <input
                            type="text"
                            className="form-control"
                            name="userName"
                            onChange={e => {
                              this.setState({ userName: e.target.value });
                            }}
                            onKeyPress={this.handleKeyPress}
                          ></input>
                          <div className="error">
                            {this.state.errors["userName"]}
                          </div>
                        </div>

                        <div>
                          <label>Password</label>
                          <input
                            type="password"
                            name="password"
                            className="form-control"
                            onChange={e => {
                              this.setState({ password: e.target.value });
                            }}
                            onKeyPress={this.handleKeyPress}
                          ></input>
                          <div className="error">
                            {this.state.errors["password"]}
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                }
                legend={
                  <Row>
                     <div>
                      {" "}
                      <u>
                        <a
                          className="text-center"
                          href="/#/auth/forgot-password-page"
                          onClick={this.forgotPassword}
                        >
                          Forgot Password?
                        </a>
                      </u>
                    </div> 
                    <br></br>
                    <Button
                      style={buttonStyle}
                      onKeyPress={this.handleKeyPress}
                      onClick={event => this.login()}
                    >
                      Log In
                    </Button>
                  </Row>
                }
                ftTextCenter
              />
              <NotificationContainer />
            </form>
          </Col>
        </Row>
      // </Grid>
    );
  }
}
export default LoginPage;
export let Token;
