// Import
import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import Card from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import ReactTable from "react-table";
import DatePicker from "react-datepicker";
import Select from "react-select";
import { CSVLink } from "react-csv";
import {
    NotificationContainer,
    NotificationManager
} from "react-notifications";
import Cookies from "js-cookie";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-table/react-table.css";
import "react-notifications/lib/notifications.css";
import "react-tabs/style/react-tabs.css";
import "react-datepicker/dist/react-datepicker.css";
import links from "../views/Server.json";
import Modal from "react-responsive-modal";

const orderHeaders = [

    { label: "Machine ID", key: "hciId" },
    { label: "Application Name", key: "applicationName" },
    { label: "Type", key: "trial" },
    { label: "Lic. Purchased On", key: "issueDate" },
    { label: "Lic. Start Date", key: "orderStartDate" },
    { label: "Lic. Expiry Date", key: "expiryDate" },
    { label: "No of Grace Granted", key: "graceCount" },  
  
  ];
  

class OrderHistory extends Component {
    constructor(props) {
        super(props);

        //state iniatialization
        this.state = {
            orders: [],
            macAppDetails:[],
            selectedApp:[],
            selectedCust:[],
            macId:[],
            mac:"",
            app:"",
            appId:[],
            issueStartDate: null,
            issueEndDate: null,
            errorStatusCode: false,
            statusCode500: false,
            textErrorCode: ""
        }
        this.handleChangeIssueStart = this.handleChangeIssueStart.bind(this);
        this.handleChangeIssueEnd = this.handleChangeIssueEnd.bind(this);
    }
    handleChangeIssueStart(date) {
        this.setState(
          {
            issueStartDate: date
        },
        () => {
          this.getOrders();
        }
      );
    }
      //order issuedate end change
      handleChangeIssueEnd(date) {
        this.setState(
          {
            issueEndDate: date
          },
          () => {
            this.getOrders();
          }
        );
      }
    getFileName() {
        let d = new Date();
        let dformat = `${d.getDate()}/${d.getDay()}/${d.getFullYear()} (${d.getHours()} hrs ${d.getMinutes()} mins)`;
        return "Order History " + dformat + ".csv";
    }

      //get the formatted date
  GetFormattedDate(date) {
    var month = date.getMonth() + 1;
    var day = date.getDate();
    var year = date.getFullYear();
    return day + "-" + month + "-" + year;
  }

    machAppDeta = e => {
        let Token = "Token " + Cookies.get("access-token");
          fetch(links.serverAddress + links.machappdetails, {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: Token
            },
          })
            .then(response => {
              const statusCode = response.status;
              const data = response.json();
              return Promise.all([statusCode, data]);
            })
            .then(json => {
              this.setState({ data: json });
              if (json[0] === 200) {
                this.setState({macAppDetails: json[1].data})
                let array = this.state.macAppDetails.slice()
                for (let i = 0; i < this.state.macAppDetails.length; i++) {
    
                  array[i].value = this.state.macAppDetails[i].id;
                  array[i].label = this.state.macAppDetails[i].MachineID;
                  
                 
                }
                this.setState({ appDetails: array },()=>{
                });
                
              } else if (json[0] === 400) {
                NotificationManager.error(
                  "Unable to get records"
                );
              } else if (json[0] === 401) {
                window.location.href = "/#/auth/login-page";
                NotificationManager.error(
                  "Unauthorised! Please Login Again"
                );
              }
            })
            .catch(error =>
              this.setState(
                {
                  error,
                  isLoading: false
                },
                NotificationManager.error("Server unexpectedly lost the connection. Please try after some time")
              )
            );
      
      };
      
    getOrders() {

        let Token = "Token " + Cookies.get("access-token");
    
        var StartDates;
    
        var EndDates;
        
        if (this.state.issueStartDate === null){ 
            StartDates = "" } 
        else StartDates = this.GetFormattedDate(this.state.issueStartDate);
    
        if (this.state.issueEndDate === null) EndDates = "";
        else EndDates = this.GetFormattedDate(this.state.issueEndDate);


        fetch(links.serverAddress + links.orderlicence, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: Token
            },
            body: JSON.stringify({
                mid: this.state.macId,
                appid: this.state.appId,
                statusAlive: this.state.statusId,
               // expiryStartDate: startdate,
               // expiryEndDate: endDate,
              issueStartDate: StartDates,
               issueEndDate: EndDates,
              })
        })
            .then(response => {
                const statuscode = response.status;
                const data = response.json();
                if(statuscode === 500){
                  this.setState({errorStatusCode: true, statusCode500: true, textErrorCode: "Oops! Internal Server Error..."})
                }
                else if(statuscode === 502 || statuscode === 504 ){
                  this.setState({errorStatusCode: true, statusCode500: true, textErrorCode: "Oops! Bad Gateway Or Service Unavailable Error..."})
                }
                return Promise.all([statuscode, data]);
            })
            .then(json => {
                this.setState({ data: json })

                if (json[0] === 200) {
                    this.setState({ orders: json[1] })
                    let array = this.state.orders.slice()
                    for(let i=0; i<this.state.orders.length; i++){
                        if(this.state.orders[i].trial === true){
                            array[i].trial = "Trial"
                            array[i].orderStartDate = array[i].graceIssueDate
                            array[i].expiryDate = array[i].graceExpiryDate
                        }
                        else if(this.state.orders[i].period === 77){
                          array[i].trial = "Perpectual"
                        }
                        else{
                              if(array[i].period === null){
                                array[i].period = 0;
                              }
                            array[i].trial = "Licence (" +array[i].period+ " months)"
                        }
                    }
                    this.setState({ orders: array })
                   
                }
                else if (json[0] === 400) {
                    NotificationManager.error("Unable to fetch records");
                }
                else if (json[0] === 401) {
                    window.location.href = "/#/auth/login-page";
                    NotificationManager.error("Unauthorized, please login!");
                }
            })

    }



    handledropdownChangeMac = selectedCust => {
        this.setState({ selectedCust,
            selectedApp:[],
            appId:[]

       });
    
        this.setState(
          { macId: [selectedCust.id], mac: selectedCust.MachineID},
          () => {           
            let array = this.state.macAppDetails.slice()
        for (let i = 0; i < this.state.macAppDetails.length; i++) {
          if(this.state.macId[0] === this.state.macAppDetails[i].value)
          {
          for(let j=0; j < this.state.macAppDetails[i].Apps.length; j++){
            array[i].Apps[j].value = this.state.macAppDetails[i].Apps[j].id;
            array[i].Apps[j].label = this.state.macAppDetails[i].Apps[j].ApplicationName;
            this.setState({ macAppDetails: array },()=>{
            this.setState({macs: this.state.macAppDetails[i].Apps},()=>{
            })
            
            });
           
          }
          }
    
        }
        this.getOrders();
          }
        );
        
      };
    
    
      handledropdownChangeApp = selectedApp => {
        this.setState({ selectedApp });
        this.setState(
          { app: selectedApp.ApplicationName, appId: [selectedApp.id] },
          () => {
            this.getOrders();
          }
        );
      };


      resetComponents() {
          this.setState({
            selectedCust:[],
            macId:[],
            selectedApp:[],
            appId:[],
            issueEndDate: null,
            issueStartDate:null

          },()=>{
              this.getOrders()
          })
         

      }
      onCloseErrorModal= () => {
        this.setState({
          errorStatusCode: true,
          statusCode500: true
        })
      }
    componentWillMount() {
        this.getOrders();
        this.machAppDeta();
    }
    render() {
      let errorcode;
      if(this.state.statusCode500 === true){
        errorcode = (
          <Modal open={this.state.errorStatusCode} onClose={this.onCloseErrorModal} center>
            <br></br>
            <br></br>
            <Card
              title={"Server Error"}
              content={
                <div>
                <Row style={{ color: "#716e6eb3"}}>          
                <Col md={12}>
                      <span  style={{fontSize: "59px"}}>{this.state.textErrorCode}</span>
                    </Col>
                    
                    </Row>
                </div>
              }
            />
       
            <NotificationContainer />
          </Modal>
        );
       }
        return (
            <div className="main-content">
                {/* <Grid fluid> */}
                    <Row>
                        <Col md={12} sm={12}>
                            <Tabs>
                                <TabList>
                                    <Tab>
                                        Order History
                                    </Tab>
                                </TabList>
                                <TabPanel>
                                    <Card 
                                    content = {
                                        <div>
                                        <div className="col-md-12">
                                        <Row>

                                        <div className="col-md-3">
                              <label>Machine </label>
                              <Select
                                value={this.state.selectedCust}
                                onChange={this.handledropdownChangeMac}
                                options={this.state.macAppDetails}
                                theme={theme => ({
                                  ...theme,
                                  borderRadius: 4,
                                  colors: {
                                    ...theme.colors,

                                    primary25: "#CCCCCC",
                                    primary: "#555555",
                                    primary50: "#CCCCCC"
                                  }
                                })}
                              />
                            </div>

                            <div className="col-md-3">
                              <label>Application </label>
                              <Select
                                value={this.state.selectedApp}
                                onChange={this.handledropdownChangeApp}
                                options={this.state.macs}
                                theme={theme => ({
                                  ...theme,
                                  borderRadius: 4,
                                  colors: {
                                    ...theme.colors,

                                    primary25: "#CCCCCC",
                                    primary: "#555555",
                                    primary50: "#CCCCCC"
                                  }
                                })}
                              />
                            </div>
                            <div className="col-md-2">
                              <label>Purchase(From)</label>
                              <DatePicker
                                className="form-control "
                                selected={this.state.issueStartDate}
                                placeholderText="Start"
                                selectsStart
                                startDate={this.state.issueStartDate}
                                endDate={this.state.issueEndDate}
                                onChange={this.handleChangeIssueStart}
                                dateFormat="dd/MM/yyyy"
                              />
                            </div>
                            <div className="col-md-2">
                              <label>Purchase(Till)</label>
                              <DatePicker
                                className="form-control "
                                selected={this.state.issueEndDate}
                                selectsEnd
                                minDate={this.state.issueStartDate}
                                startDate={this.state.issueStartDate}
                                endDate={this.state.issueEndDate}
                                onChange={this.handleChangeIssueEnd}
                                dateFormat="dd/MM/yyyy"
                                placeholderText="End"
                              />
                            </div>

                            <button
                            type="button"
                            className="table-btn"
                            title="Reset Search"
                            style={{position: "relative", margin: "25px 0px 0px 10px", border: "1px solid #cccccc", borderRadius: "11px", height: "37px", width: "41px"}}
                            onClick={() => {
                              this.resetComponents();
                            }}
                          >
                            <i
                              className="fa fa-refresh"
                              aria-hidden="true"
                              style={{fontSize: "20px", margin: "5px 0px 0px 9px"}}
                            ></i>
                          </button>
                            
                                        </Row>
                                        <br></br>
                                <br></br>
                                        </div>

                                
                                            <ReactTable
                                            data = {this.state.orders}
                                            columns = {
                                                [
                                                    {
                                                        Header: "ID",
                                                        id: "row",
                                                        Cell: row => {
                                                          return <div>{row.index + 1}</div>;
                                                        },
                                                        sortable: false,
                                                        filterable: false,
                                                        width: 80,
                                                        foldable: true
                                                    },
                                                    {
                                                        Header: "Machine ID",
                                                        accessor: "hciId",
                                                        width: 150,
                                                        foldable: true
                                                    },
                                                    {
                                                        Header: "Application Name",
                                                        accessor: "applicationName",
                                                        width: 150,
                                                        foldable: true
                                                    },
                                                    {
                                                        Header: "Type",
                                                        accessor: "trial",
                                                        width: 150,
                                                        foldable: true,

                                                    },
                                                    {
                                                        Header: "Order Issue Date",
                                                        accessor: "issueDate",
                                                        width: 150,
                                                        foldable: true
                                                    },
                                                    {
                                                        Header: "Lic. Start Date",
                                                        accessor: "orderStartDate",
                                                        width: 150,
                                                        foldable: true
                                                    },
                                                    {
                                                        Header: "Lic. End Date",
                                                        accessor: "expiryDate",
                                                        width: 150,
                                                        foldable: true
                                                    },
                                                    {
                                                        Header: "No of times Grace Issued",
                                                        accessor: "graceCount",
                                                        width: 150,
                                                        foldable: true
                                                    },
                                                ]
                                            }
                                            defaultPageSize= {10}
                                            showPaginationBottom={true}
                                            className="-striped -highlight customer"
                                            defaultFilterMethod={(filter, row, column) => {
              const id = filter.pivotId || filter.id;
              return row[id] !== undefined
                ? String(row[id])
                    .toLowerCase()
                    .indexOf(filter.value.toLowerCase()) !== -1
                : true;
            }}
                                            />
                                            <br></br>
                                    <Button title="Export" bsStyle="info" pullRight>
                                        {this.state.orders ? <CSVLink style={{ color: 'whitesmoke' }} filename={this.getFileName()} headers={orderHeaders} data={this.state.orders}>Export</CSVLink> : null}
                                    </Button>
                                    <br></br>
                                    <br></br>
                                        </div>
                                        
                                    }
                                    />
                                    

                                    <NotificationContainer/>
                                </TabPanel>
                            </Tabs>
                        </Col>
                    </Row>
                    {errorcode}
                {/* </Grid> */}
            </div>
        );
    }
}

export default OrderHistory;