import React, { Component } from "react";

export class StatsCard extends Component {
  render() {
    return (
      <div className="card card-stats" style={{"WebkitBoxShadow": "-16px 15px 5px 0px rgba(0,0,0,0.46)",
"MozBoxShadow": "-16px 15px 5px 0px rgba(0,0,0,0.46)",
"boxShadow": "-16px 15px 50px 0px rgba(0,0,0,0.46)",
margin: "6px 0px 0px 0px"}}>
        <div className="content">
          <div className="row">
            <div className="col-xs-3">
              <div className="icon-big text-center icon-warning">
                {this.props.bigIcon}
              </div>
            </div>
            <div className="col-xs-9">
              <div className="numbers">
                <p>{this.props.statsText}</p>
                <span style={{ wordBreak: 'break-word' }}>{this.props.statsValue}</span>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="footer">
          <hr />
          <div className="stats">
            {this.props.statsIcon} {this.props.statsIconText}
          </div>
        </div> */}
      </div>
    );
  }
}

export default StatsCard;
